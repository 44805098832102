import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { Button, ChecksGroup, InfoAlert, P, SlidePanel, Ui } from "common/components/atoms";
import { dateFormatWithTime } from "common/components/atoms/DatePicker/DatePicker";
import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import DocumentUploadWithStatusesSignatureEmailInvitation from "common/components/molecules/DocumentUploadWithStatusesSignatureEmailInvitation/DocumentUploadWithStatusesSignatureEmailInvitation";
import ReceiverDetailsV2 from "common/components/molecules/ReceiverDetailsV2/ReceiverDetailsV2";
import { RelationshipTypesEnum } from "common/enums/enum";
import { WarningExclamationMarkIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import FormValidatorToast from "../../../../financial-instruments/FormValidatorToast";
import { IssueSharesPostDTO } from "../../../types";
import { documentationDetailsValidationSchema } from "../form-sections/documentation-details/validation";
import { receiverDetailsValidationSchema } from "../form-sections/receiver-details/validation";
import SharesDetails from "../form-sections/shares-details/shares-details";
import { sharesDetailsValidationSchema } from "../form-sections/shares-details/validation";
import TransactionDetails, { SHARE_ISSUES } from "../form-sections/transaction-details/transaction-details";
import { transactionDetailsValidationSchema } from "../form-sections/transaction-details/validation";
import { IssueSharesFields } from "./form-fields";

type PropsTypes = {
  onSubmit: (transaction: FormData, restartForm?: boolean) => Promise<boolean>;
  onClose: () => void;
  isDisabled?: boolean;
};

const PANEL_HEADER_ID = "equity-shares-id";

const t = createTranslation(TranslationNS.pages, "company.transactions");
const tValidation = createTranslation(TranslationNS.validation);

const CreateIssueSharesForm: FC<PropsTypes> = ({ onClose, onSubmit, isDisabled }) => {
  const formRef = useRef<HTMLDivElement>(null);
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const { shareClassesCompany } = useStoreState((state) => state.shareClassModel);

  const [isAddAnother, setIsAddAnother] = useState(false);

  const initialValues: IssueSharesPostDTO = useMemo(() => {
    return {
      [IssueSharesFields.transactionTypeId]: 0,
      [IssueSharesFields.transactedAt]: "",
      [IssueSharesFields.description]: "",

      [IssueSharesFields.companyId]: companyId,
      [IssueSharesFields.firstName]: "",
      [IssueSharesFields.lastName]: "",
      [IssueSharesFields.email]: "",
      [IssueSharesFields.isCompanyOwned]: false,
      [IssueSharesFields.companyName]: "",
      [IssueSharesFields.organizationNumber]: "",
      [IssueSharesFields.numberOfShares]: 0,
      [IssueSharesFields.sharePurchaseDate]: format(new Date().setHours(12, 0, 0, 0), dateFormatWithTime),
      [IssueSharesFields.purchasePrice]: 0,
      [IssueSharesFields.shareClassId]: shareClassesCompany[0].id,
      [IssueSharesFields.relationshipTypeId]: RelationshipTypesEnum.EMPLOYEE,
      [IssueSharesFields.address]: "",
      [IssueSharesFields.dateOfBirth]: "",
      [IssueSharesFields.businessPostAddress]: "",
      [IssueSharesFields.businessEmail]: "",
      [IssueSharesFields.countryId]: 0,
      [IssueSharesFields.phoneNumber]: "",
      [IssueSharesFields.stakeholderId]: 0,
      [IssueSharesFields.documents]: [],
      [IssueSharesFields.documentStatusId]: undefined as unknown as number,
      // [IssueSharesFields.documentsNeedsSignature]: false,
      // [IssueSharesFields.sendInvitationEmail]: false,
    };
  }, [companyId, shareClassesCompany]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...transactionDetailsValidationSchema({ tFunction: tValidation, excludeFields: { transactedAt: true } }),
        ...sharesDetailsValidationSchema(tValidation),
        ...receiverDetailsValidationSchema(tValidation),
        ...documentationDetailsValidationSchema(tValidation),
      }),
    []
  );

  const handleSubmit = useCallback(
    async (values: IssueSharesPostDTO, actions: FormikHelpers<IssueSharesPostDTO>) => {
      const formData = new FormData();

      Object.values(IssueSharesFields).forEach((key) => {
        const formValue = values[key];

        if (key === IssueSharesFields.transactionTypeId && !values[key]) {
          formData.append(key, SHARE_ISSUES.toString());
        }

        if (!formValue) return;

        if (key === IssueSharesFields.documents) {
          if (!R.isEmpty(formValue)) {
            values.documents.forEach((el) => formData.append("FilesData.Files", el));
          }
        } else {
          formData.append(key, String(formValue).trim());
        }
      });

      const createdSuccessfully = await onSubmit(formData, isAddAnother);

      if (!createdSuccessfully) {
        actions.setSubmitting(false);
      }

      if (isAddAnother) {
        actions.resetForm();
        formRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    [isAddAnother, onSubmit]
  );

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ values, errors, touched, setFieldValue, setTouched, isSubmitting, handleSubmit }) => {
        // const optionalFields = !values.sendInvitationEmail
        //   ? R.append(fields.email, initialOptionalReceiverDetailsFields)
        //   : initialOptionalReceiverDetailsFields;

        const handleDocumentChange = (data: FilesDataMultiple) => {
          setFieldValue(IssueSharesFields.documents, data.newFiles);
          setTouched({ ...touched, [IssueSharesFields.documents]: true }, false);
        };

        return (
          <Form>
            <SlidePanel show={true} customRef={formRef}>
              <FormValidatorToast panelId={PANEL_HEADER_ID} />

              {isDisabled && (
                <InfoAlert
                  className="p-3 mt-7 mb-5"
                  type="Warning"
                  customContent={
                    <div className="d-flex">
                      <WarningExclamationMarkIcon height={24} width={56} color={scssVariables.warning900} />
                      <div className="ms-2">
                        <Ui.m bold className="mb-1">
                          {t("issueSharesForm.pendingTransactionTitle")}
                        </Ui.m>
                        <Ui.s>{t("issueSharesForm.pendingTransactionContent")}</Ui.s>
                      </div>
                    </div>
                  }
                />
              )}

              <SlidePanel.Header title={t("issueSharesForm.title")} onHide={onClose} />

              <SlidePanel.Section title={t("transactionDetails.title")}>
                <TransactionDetails hiddenFields={{ transactedAt: true }} isDisabled={isDisabled} />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("receiverDetails.title")}>
                <ReceiverDetailsV2 isSearchable isDisabled={isDisabled} />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("sharesDetails.title")}>
                <SharesDetails isDisabled={isDisabled} isDateWithTime={true} />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("documentationDetails.title")}>
                <P.s className="mb-4">{t("documentationDetails.description")}</P.s>

                <DocumentUploadWithStatusesSignatureEmailInvitation
                  error={errors.documentStatusId as unknown as string}
                  touched={touched.documentStatusId}
                  documentTouched={touched.documents as boolean}
                  documentStatus={values.documentStatusId || 0}
                  // isNeedSignatureChecked={values.documentsNeedsSignature}
                  // isNeedEmailInvitationChecked={values.sendInvitationEmail}
                  onFileUploaderChange={handleDocumentChange}
                  setDocumentStatus={(statusId) => {
                    setFieldValue(IssueSharesFields.documentStatusId, statusId);
                  }}
                  // setIsNeedEmailInvitationChecked={(isChecked) => {
                  //   setFieldValue(IssueSharesFields.sendInvitationEmail, isChecked);
                  // }}
                  // setIsNeedSignatureChecked={(isChecked) => {
                  //   setFieldValue(IssueSharesFields.documentsNeedsSignature, isChecked);
                  //   if (isChecked) {
                  //     setFieldValue(IssueSharesFields.sendInvitationEmail, isChecked);
                  //   }
                  // }}
                  isInviteFlowDisabled={true}
                  isRadioDisabled={isDisabled}
                />
              </SlidePanel.Section>

              <ChecksGroup className="mt-5" isDisabled={isDisabled}>
                <ChecksGroup.Check
                  label={t("sharedFormButtons.addAnother")}
                  checked={isAddAnother}
                  onChange={(event) => setIsAddAnother(event.target.checked)}
                  disabled={isDisabled}
                />
              </ChecksGroup>

              <SlidePanel.Actions>
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  onClick={() => handleSubmit()}
                  isDisabled={isDisabled}
                  className="me-3"
                >
                  {t("sharedFormButtons.submitBtn")}
                </Button>
                <Button isDisabled={isSubmitting} onClick={onClose} variant="secondary">
                  {t("sharedFormButtons.cancelBtn")}
                </Button>
              </SlidePanel.Actions>
            </SlidePanel>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateIssueSharesForm;
