import { DocumentationDetailsFields } from "../form-sections/documentation-details/form-fields";
import { ReceiverDetailsFields } from "../form-sections/receiver-details/form-fields";
import { SellerDetailsFields } from "../form-sections/seller-details/form-fields";
import { SharesDetailsFields } from "../form-sections/shares-details/form-fields";
import { TransactionDetailsFields } from "../form-sections/transaction-details/form-fields";

export const BuySellFields = {
  companyId: "companyId",
  ...ReceiverDetailsFields,
  ...SharesDetailsFields,
  ...DocumentationDetailsFields,
  ...SellerDetailsFields,
  description: TransactionDetailsFields.description,
  transactedAt: TransactionDetailsFields.transactedAt,
} as const;

export const EditBuySellFields = {
  transactionId: "transactionId",
  companyId: "companyId",
  ...ReceiverDetailsFields,
  ...SharesDetailsFields,
  ...DocumentationDetailsFields,
  ...SellerDetailsFields,
  description: TransactionDetailsFields.description,
  transactedAt: TransactionDetailsFields.transactedAt,
} as const;
