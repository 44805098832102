import { ChangeEvent, FC, useCallback, useMemo } from "react";
import { useFormikContext } from "formik";

import { DatePicker, Dropdown, Helper, TextArea } from "common/components/atoms";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { TransactionDetailsFields } from "./form-fields";
import { TransactionDetailsFieldsType } from "./types";

type PropsTypes = {
  hiddenFields?: {
    [TransactionDetailsFields.transactedAt]?: boolean;
    [TransactionDetailsFields.transactionTypeId]?: boolean;
    [TransactionDetailsFields.description]?: boolean;
  };
  minTransactionDate?: string;
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionDetails");

export const SHARE_ISSUES = 2;

const TransactionDetails: FC<PropsTypes> = ({ hiddenFields, minTransactionDate, isDisabled }) => {
  const { values, errors, touched, setFieldValue, handleChange } = useFormikContext<TransactionDetailsFieldsType>();
  const transactionTypesIssuing = useStoreState((state) => state.common.dropdowns)?.transactionTypesIssuing;

  const transactionTypesList = useMemo(() => {
    if (!transactionTypesIssuing) return [];
    return transactionTypesIssuing.filter((type) => ![SHARE_ISSUES].includes(type.id));
  }, [transactionTypesIssuing]);

  const handleTypeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(TransactionDetailsFields.transactionTypeId, e.target.value === "" ? undefined : e.target.value);
    },
    [setFieldValue]
  );

  const minDateInfo = useMemo(() => {
    if (minTransactionDate) {
      return t("minDateInfo", { minDate: transformDateToCommonDateFormat(minTransactionDate) });
    }
  }, [minTransactionDate]);

  return (
    <div data-testid="transaction-details-from-id">
      <Helper>
        <div className="d-flex mb-5">
          {!hiddenFields?.transactionTypeId && (
            <Dropdown
              isOptional
              isClearable
              name={TransactionDetailsFields.transactionTypeId}
              label={t("type")}
              options={transactionTypesList}
              selectedValue={values?.transactionTypeId}
              optionsIsObject
              isTouched={touched.transactionTypeId}
              error={errors.transactionTypeId}
              onChange={handleTypeChange}
              placeholder={t("typePlaceholder")}
              className="me-4 w-50"
              isDisabled={isDisabled}
            />
          )}
          {!hiddenFields?.transactedAt && (
            <div className="w-100">
              <Helper.Question className="w-50" questionId={TransactionDetailsFields.transactedAt} type="input">
                <DatePicker
                  name={TransactionDetailsFields.transactedAt}
                  label={t("date")}
                  date={values.transactedAt}
                  error={errors.transactedAt}
                  isTouched={touched.transactedAt}
                  minDate={minTransactionDate ? new Date(minTransactionDate) : undefined}
                  isDateOnlyString
                  isWithTimeSelect
                  onChange={(date) => {
                    setFieldValue(TransactionDetailsFields.transactedAt, date);
                  }}
                  info={minDateInfo}
                  isDisabled={isDisabled}
                />
              </Helper.Question>
              <Helper.Answer
                className="mt-3"
                withRightMargin
                answerId={TransactionDetailsFields.transactedAt}
                text={t("dateHelp")}
              />
            </div>
          )}
        </div>
        {!hiddenFields?.description && (
          <>
            <Helper.Question questionId={TransactionDetailsFields.description} type="input">
              <TextArea
                name={TransactionDetailsFields.description}
                label={t("description")}
                placeholder={t("descriptionPlaceholder")}
                isOptional
                value={values.description}
                error={errors.description}
                isTouched={touched.description}
                onChange={handleChange}
                isDisabled={isDisabled}
              />
            </Helper.Question>
            <Helper.Answer
              className="mt-3"
              withRightMargin
              answerId={TransactionDetailsFields.description}
              text={t("descriptionHelp")}
            />
          </>
        )}
      </Helper>
    </div>
  );
};

export default TransactionDetails;
