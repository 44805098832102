import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import * as R from "ramda";
import { isEmpty } from "ramda";
import * as Yup from "yup";

import { Button, ChecksGroup, InfoAlert, P, SlidePanel, Ui } from "common/components/atoms";
import { dateFormatWithTime } from "common/components/atoms/DatePicker/DatePicker";
import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import DocumentUploadWithStatusesSignatureEmailInvitation from "common/components/molecules/DocumentUploadWithStatusesSignatureEmailInvitation/DocumentUploadWithStatusesSignatureEmailInvitation";
import ReceiverDetailsV2 from "common/components/molecules/ReceiverDetailsV2/ReceiverDetailsV2";
import { RelationshipTypesEnum } from "common/enums/enum";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { WarningExclamationMarkIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { AvailableShareClass, BuySellPostDTO } from "../../../types";
import { documentationDetailsValidationSchema } from "../form-sections/documentation-details/validation";
import { receiverDetailsValidationSchema } from "../form-sections/receiver-details/validation";
import SellerDetails from "../form-sections/seller-details/seller-details";
import { sellerDetailsValidationSchema } from "../form-sections/seller-details/validation";
import SharesDetailsV2 from "../form-sections/shares-details-v2/shares-details-v2";
import { sharesDetailsValidationSchema } from "../form-sections/shares-details-v2/validation";
import TransactionDetails from "../form-sections/transaction-details/transaction-details";
import { transactionDetailsValidationSchema } from "../form-sections/transaction-details/validation";
import { IssueSharesFields } from "../issue-shares/form-fields";
import { BuySellFields } from "./form-fields";
import SubmitModal from "./submit-modal";

type PropsTypes = {
  onSubmit: (transaction: FormData, resetForm?: boolean) => Promise<boolean>;
  onClose: () => void;
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");
const tValidation = createTranslation(TranslationNS.validation);

const CreateBuySellTransactionForm: FC<PropsTypes> = ({ onSubmit, onClose, isDisabled }) => {
  const formRef = useRef<HTMLDivElement>(null);
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const { shareClassesCompany } = useStoreState((state) => state.shareClassModel);

  const [isAddAnother, setIsAddAnother] = useState(false);
  const [selectedShareClass, setSelectedShareClass] = useState<AvailableShareClass | null>(null);
  const [isApproveSubmitModalOpen, setIsApproveSubmitModalOpen] = useState(false);

  const dataManagerRef = useRef<any>(null);

  const formInitialValues: BuySellPostDTO = useMemo(
    () => ({
      [BuySellFields.companyId]: +companyId,
      [BuySellFields.transactedAt]: format(new Date().setHours(12, 0, 0, 0), dateFormatWithTime),
      [BuySellFields.description]: "",

      [BuySellFields.sellerStakeholderId]: 0,

      [BuySellFields.firstName]: "",
      [BuySellFields.lastName]: "",
      [BuySellFields.email]: "",
      [BuySellFields.isCompanyOwned]: false,
      [BuySellFields.companyName]: "",
      [BuySellFields.organizationNumber]: "",
      [BuySellFields.numberOfShares]: 0,
      [BuySellFields.purchasePrice]: 0,
      [BuySellFields.shareClassId]: shareClassesCompany.length === 1 ? shareClassesCompany[0].id : undefined,
      [BuySellFields.documents]: [],
      [BuySellFields.relationshipTypeId]: RelationshipTypesEnum.EMPLOYEE,
      [BuySellFields.documentStatusId]: undefined as unknown as number,
      // [BuySellFields.documentsNeedsSignature]: false,
      // [BuySellFields.sendInvitationEmail]: false,
      [BuySellFields.address]: "",
      [BuySellFields.dateOfBirth]: "",
      [BuySellFields.businessPostAddress]: "",
      [BuySellFields.businessEmail]: "",
      [BuySellFields.countryId]: 0,
      [BuySellFields.phoneNumber]: "",
      [BuySellFields.stakeholderId]: 0,
    }),
    // eslint-disable-next-line
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...transactionDetailsValidationSchema({ tFunction: tValidation, excludeFields: { transactionTypeId: true } }),
        ...sellerDetailsValidationSchema(tValidation),
        ...receiverDetailsValidationSchema(tValidation),
        ...sharesDetailsValidationSchema(tValidation, selectedShareClass?.sharesAvailable || 0),
        ...documentationDetailsValidationSchema(tValidation),
      }),
    [selectedShareClass?.sharesAvailable]
  );

  const handleSubmit = useCallback(
    async (values: BuySellPostDTO, actions: FormikHelpers<BuySellPostDTO>) => {
      const formData = new FormData();

      Object.values(BuySellFields).forEach((key) => {
        const formValue = values[key];

        if (!formValue) return;

        if (key === BuySellFields.documents) {
          if (!R.isEmpty(formValue)) {
            values.documents.forEach((el: any) => formData.append("FilesData.Files", el));
          }
        } else {
          formData.append(key, String(formValue).trim());
        }
      });

      const createdSuccessfully = await onSubmit(formData, isAddAnother);

      if (!createdSuccessfully) {
        actions.setSubmitting(false);
      }

      if (isAddAnother) {
        setIsApproveSubmitModalOpen(false);
        actions.resetForm();
        formRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    [isAddAnother, onSubmit]
  );

  const openApproveSubmitModal = useCallback(() => {
    setIsApproveSubmitModalOpen(true);
  }, []);

  const closeApproveSubmitModal = useCallback(() => {
    setIsApproveSubmitModalOpen(false);
  }, []);

  return (
    <Formik onSubmit={handleSubmit} initialValues={formInitialValues} validationSchema={validationSchema}>
      {({ values, errors, touched, setFieldValue, isSubmitting, validateForm, submitForm }) => {
        const handleDocumentChange = (data: FilesDataMultiple) => {
          setFieldValue(IssueSharesFields.documents, data.newFiles);
        };

        return (
          <Form>
            <SlidePanel show={true} customRef={formRef}>
              <SlidePanel.Header title={t("buySellForm.title")} onHide={onClose} />

              {isDisabled && (
                <InfoAlert
                  className="p-3 mt-7 mb-5"
                  type="Warning"
                  customContent={
                    <div className="d-flex">
                      <WarningExclamationMarkIcon height={24} width={56} color={scssVariables.warning900} />
                      <div className="ms-2">
                        <Ui.m bold className="mb-1">
                          {t("buySellForm.pendingTransactionTitle")}
                        </Ui.m>
                        <Ui.s>{t("buySellForm.pendingTransactionContent")}</Ui.s>
                      </div>
                    </div>
                  }
                />
              )}

              <SlidePanel.Section title={t("transactionDetails.title")}>
                <TransactionDetails hiddenFields={{ transactionTypeId: true }} isDisabled={isDisabled} />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("buySellForm.seller")}>
                <SellerDetails
                  setSelectedShareClass={setSelectedShareClass}
                  selectedShareClassId={selectedShareClass?.shareClassId || null}
                  isDisabled={isDisabled}
                />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("sharesDetails.title")}>
                <SharesDetailsV2 selectedShareClass={selectedShareClass} isDisabled={isDisabled} />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("buySellForm.buyer")}>
                <ReceiverDetailsV2 isSearchable isDisabled={isDisabled} />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("documentationDetails.title")}>
                <P.s className="mb-4">{t("documentationDetails.description")}</P.s>

                <DocumentUploadWithStatusesSignatureEmailInvitation
                  error={errors.documentStatusId as unknown as string}
                  touched={touched.documentStatusId}
                  documentStatus={values.documentStatusId || 0}
                  onFileUploaderChange={handleDocumentChange}
                  setDocumentStatus={(statusId) => {
                    setFieldValue(IssueSharesFields.documentStatusId, statusId);
                  }}
                  isInviteFlowDisabled={true}
                  isRadioDisabled={isDisabled}
                  dataManageRef={dataManagerRef}
                />
              </SlidePanel.Section>
              <ChecksGroup className="mt-5" isDisabled={isDisabled}>
                <ChecksGroup.Check
                  label={t("sharedFormButtons.addAnother")}
                  checked={isAddAnother}
                  onChange={(event) => setIsAddAnother(event.target.checked)}
                  disabled={isDisabled}
                />
              </ChecksGroup>

              <SlidePanel.Actions>
                <Button
                  isLoading={isSubmitting}
                  onClick={async () => {
                    const errors = await validateForm();
                    if (isEmpty(errors)) {
                      openApproveSubmitModal();
                    } else {
                      submitForm();
                    }
                  }}
                  type="submit"
                  className="me-3"
                  isDisabled={isDisabled}
                >
                  {t("sharedFormButtons.submitBtn")}
                </Button>
                <Button isDisabled={isSubmitting} onClick={onClose} variant="secondary">
                  {t("sharedFormButtons.cancelBtn")}
                </Button>
              </SlidePanel.Actions>
            </SlidePanel>
            {isApproveSubmitModalOpen && (
              <SubmitModal dataManagerRef={dataManagerRef} onClose={closeApproveSubmitModal} />
            )}
            <ToastFormikValidator />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateBuySellTransactionForm;
